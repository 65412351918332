import styled, { css } from 'styled-components';

export const Wrapper = styled.li.withConfig({
    shouldForwardProp: (prop) => !['selected'].includes(prop),
})<{
    selected: boolean;
}>`
    ${({ theme, selected }) => css`
        display: flex;
        align-items: center;
        color: ${theme.palette.common.text};
        font-weight: 500;
        padding: ${theme.gap.vsmall} ${theme.gap.small} ${theme.gap.vsmall} ${theme.gap.vsmall};
        border-top: 0.1rem solid ${theme.palette.common.divider};
        cursor: pointer;
        transition: color ${theme.base.transition};

        &:first-child {
            border-top: unset;
        }

        &:hover {
        }

        ${selected &&
        css`
            font-weight: 700;
            background-color: ${theme.palette.color.gray.opacity.high};
            border-radius: 10rem;

            &:hover {
            }

            & + li {
                border-top-color: transparent;
            }
        `}

        a {
            color: inherit;
        }
    `}
`;

export const Inner = styled.div`
    ${() => css`
        display: flex;
        align-items: center;
        width: 100%;
    `}
`;

export const IconWrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['selected'].includes(prop),
})<{
    selected: boolean;
}>`
    ${({ theme, selected }) => css`
        margin-right: ${theme.gap.vsmall};
        width: 3.2rem;
        min-width: 3.2rem;
        height: 3.2rem;
        border-radius: 100%;
        background-color: ${theme.palette.color.gray.opacity.high};
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: background ${theme.base.transition};

        i {
            width: 1.4rem;
            min-width: 1.4rem;
            height: 1.4rem;
            transition: color ${theme.base.transition};
        }

        @media ${theme.breakpoints.md.min} {
            width: 4rem;
            min-width: 4rem;
            height: 4rem;

            i {
                width: 1.8rem;
                min-width: 1.8rem;
                height: 1.8rem;
            }
        }

        ${Wrapper}:hover & {
            background-color: ${theme.palette.color.gray.main};

            i {
                color: ${theme.palette.color.white.main};
            }
        }

        ${selected &&
        css`
            background-color: ${theme.palette.color.gray.main};

            i {
                color: ${theme.palette.color.white.main};
            }
        `}
    `}
`;

export const Label = styled.div.withConfig({
    shouldForwardProp: (prop) => !['selected'].includes(prop),
})<{
    selected: boolean;
}>`
    ${({ theme, selected }) => css`
        flex: 1;
        transition: color ${theme.base.transition};

        ${Wrapper}:hover & {
            color: ${theme.palette.common.textMuted};
        }

        ${selected &&
        css`
            color: ${theme.palette.common.textMuted};
        `}
    `}
`;

export const Value = styled.div`
    ${({ theme }) => css`
        margin-left: ${theme.gap.medium};
        color: ${theme.palette.common.textMuted};
    `}
`;
