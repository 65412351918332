import { observer } from 'mobx-react-lite';
import React from 'react';
import { useRootStore } from '../../../../providers/root-store-provider';
import { Dropdown } from '../../dropdown/dropdown';
import { Responsive } from '../../responsive/responsive';
import { PortfolioMenu } from '../menu/portfolio-menu';
import { MenuIndicatorWrapper, NameLabel, Wrapper, WrapperMobile } from './portfolio-switcher.styled';
import { IconArrowDown } from '../../icons/arrow-down';
import { PortfolioMenuMobile } from '../menu/mobile/portfolio-menu-mobile';
import { IconPortfolio } from '../../icon/portfolio/icon-portfolio';
import { VerticalDivider } from '../../../../blocks/app/header/header.styled';
import { HeaderButtonPrivate } from '../../../../blocks/app/header/button/private/header-button-private';

export const PortfolioSwitcher: React.FC = observer(() => {
    const { portfolioStore, themeStore } = useRootStore();
    const myPortfolio = portfolioStore.selectedPortfolio;

    return (
        <Wrapper>
            <Responsive breakpoint="md">
                <Dropdown position={'bottomLeft'} content={<PortfolioMenu />}>
                    <MenuIndicatorWrapper>
                        <IconPortfolio
                            type={myPortfolio.iconName}
                            size={{ xs: 'xsmall', xl: 'small', xxl: 'medium' }}
                            variant={myPortfolio.colorName}
                        />
                        <NameLabel>{myPortfolio.name}</NameLabel>
                        <IconArrowDown />
                    </MenuIndicatorWrapper>
                </Dropdown>
            </Responsive>
            <Responsive breakpoint="md" type="max">
                <MenuIndicatorWrapper
                    onClick={() => {
                        themeStore.toggleMobilePortfolioNavigationDisplay();
                        themeStore.hideMobileNavigation();
                    }}
                >
                    <IconPortfolio
                        type={myPortfolio.iconName}
                        size={{ xs: 'xsmall', xl: 'small', xxl: 'medium' }}
                        variant={myPortfolio.colorName}
                    />
                    <IconArrowDown />
                </MenuIndicatorWrapper>
            </Responsive>
            <WrapperMobile showOnMobile={themeStore.mobilePortfolioNavigationDisplay}>
                <PortfolioMenuMobile />
            </WrapperMobile>
            <VerticalDivider />
            <HeaderButtonPrivate />
        </Wrapper>
    );
});
