import React, { useState } from 'react';
import { StornoReasonEnum } from '@app/common';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useRootStore } from '../../../../providers/root-store-provider';
import { BaseDialog } from '../base-dialog';
import { OffBoardingStep1 } from './step1/offboarding-step1';
import { OffBoardingStep1Action } from './step1/action/offboarding-step1-action';
import { OffBoardingStep2, RadioOptionsType } from './step2/offboarding-step2';
import { OffBoardingStep2Action } from './step2/action/offboarding-step2-action';
import { OffBoardingStep3 } from './step3/offboarding-step3';
import { OffBoardingStep3Action } from './step3/action/offboarding-step3-action';
import { OffBoardingStep4Action } from './step4/action/offboarding-step4-action';
import { StornoStepEnum } from '../../../../models/enums/storno-step-enum';
import { LookupKeyEnum } from '../../../../defs/api';

interface OffBoardingDialogProps {
    lookupKey: LookupKeyEnum;
}

export const OffBoardingDialog: React.FC<OffBoardingDialogProps> = observer(() => {
    const { t } = useTranslation();
    const { offboardingStore, stripeStore, authStore } = useRootStore();
    const [step, setStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isCancelLoading, setIsCancelLoading] = useState(false);

    const [selectedOption, setSelectedOption] = useState<RadioOptionsType>(null);
    const [fieldValue, setFieldValue] = useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption((event.target as HTMLInputElement).value as RadioOptionsType);
        setFieldValue('');
    };

    const onCloseDialog = () => {
        offboardingStore.close();
        setStep(StornoStepEnum.Preferences);
    };

    const onCancelSubscription = async () => {
        setIsCancelLoading(true);
        await stripeStore.cancelSubscription(selectedOption as StornoReasonEnum, fieldValue);
        await authStore.fetchUserProfile();
        onCloseDialog();
        setIsCancelLoading(false);
    };

    const onDiscount = async () => {
        setIsLoading(true);
        await stripeStore.setStornoDiscount();
        stripeStore.startShowGotDiscountAlert();
        onCloseDialog();
        setIsLoading(false);
    };

    const getAction = () => {
        switch (step) {
            case StornoStepEnum.Reason: {
                return (
                    <OffBoardingStep2Action
                        onContinue={async () => {
                            // TODO: change after implementation of stripeStore.setStornoDiscount();
                            const hadStornoDiscount = true;
                            // const hadStornoDiscount = await userStore.hadStornoDiscount();

                            if (hadStornoDiscount) {
                                setStep(StornoStepEnum.AlreadyHaveDiscount);
                            } else {
                                setStep(StornoStepEnum.Discount);
                            }
                        }}
                        onCancel={onCloseDialog}
                        isSelectedOption={!!selectedOption}
                        isFieldValueEmpty={
                            (fieldValue.length === 0 && selectedOption === 'other') ||
                            (fieldValue.length === 0 && selectedOption === 'competition')
                        }
                        isLoading={isLoading}
                    />
                );
            }
            case StornoStepEnum.Discount: {
                return (
                    <OffBoardingStep3Action
                        onContinue={onCancelSubscription}
                        onCancel={onDiscount}
                        isLoading={isLoading}
                        isCancelLoading={isCancelLoading}
                    />
                );
            }
            case StornoStepEnum.AlreadyHaveDiscount: {
                return (
                    <OffBoardingStep4Action
                        onContinue={onCancelSubscription}
                        onCancel={onCloseDialog}
                        isLoading={isLoading}
                        isCancelLoading={isCancelLoading}
                    />
                );
            }
            default: {
                return (
                    <OffBoardingStep1Action
                        onContinue={() => setStep(StornoStepEnum.Reason)}
                        onCancel={onCloseDialog}
                    />
                );
            }
        }
    };

    const getContent = () => {
        switch (step) {
            case StornoStepEnum.Reason: {
                return (
                    <OffBoardingStep2
                        selectedOption={selectedOption}
                        handleChooseOption={handleChange}
                        setFieldValue={setFieldValue}
                    />
                );
            }
            case StornoStepEnum.Discount: {
                return <OffBoardingStep3 />;
            }
            case StornoStepEnum.AlreadyHaveDiscount: {
                return <></>;
            }
            default: {
                return <OffBoardingStep1 />;
            }
        }
    };

    return (
        <BaseDialog
            opened={offboardingStore.opened}
            close={onCloseDialog}
            locked={step === StornoStepEnum.Discount}
            action={getAction()}
            title={t(`dialogs.offboarding.step${step}.title`)}
            content={getContent()}
            width={46}
            align={step === StornoStepEnum.AlreadyHaveDiscount ? 'center' : 'left'}
        />
    );
});
