import * as React from 'react';
import { Wrapper } from './dropdown-menu.styled';

interface DropdownMenuPropsInterface {
    children: React.ReactChild;
}

export const DropdownMenu: React.FC<DropdownMenuPropsInterface> = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};
