import { LayoutEnum } from '@app/common';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['type'].includes(prop),
})<{
    type: LayoutEnum;
}>(
    ({ theme, type }) => css`
        display: flex;

        @media ${theme.breakpoints.vl.max} {
            flex-direction: column;
        }

        @media ${theme.breakpoints.vl.min} {
            ${type === LayoutEnum.Horizontal &&
            css`
                flex-direction: row;
            `}

            ${type === LayoutEnum.Vertical &&
            css`
                flex-direction: column;
            `}
        }
    `,
);

export const Content = styled.div.withConfig({
    shouldForwardProp: (prop) => !['type'].includes(prop),
})<{
    type: LayoutEnum;
}>(
    ({ theme, type }) => css`
        flex: 1;
        width: 100%;
        position: relative;

        @media ${theme.breakpoints.vl.min} {
            ${type === LayoutEnum.Horizontal &&
            css`
                padding-top: 8rem;
                padding-left: 23.2rem;
            `}
        }
    `,
);

export const Overlay = styled.div(
    ({ theme }) => css`
        position: fixed;
        inset: 0;
        z-index: 799;
        background: ${theme.palette.color.secondary.main};
        opacity: 0.2;
    `,
);

export const Bottom = styled.div(
    ({ theme }) => css`
        @media ${theme.breakpoints.vl.max} {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: ${theme.gap.xsmall} ${theme.gap.small};
            border-top: 0.1rem solid ${theme.palette.common.border};
        }
    `,
);

export const ContentInner = styled.div.withConfig({
    shouldForwardProp: (prop) => !['showBar'].includes(prop),
})<{
    showBar: boolean;
}>(
    ({ theme, showBar }) => css`
        padding: ${theme.gap.small} 0;

        ${showBar &&
        css`
            padding-top: calc(${theme.base.barHeight} + ${theme.gap.small});
        `}

        @media ${theme.breakpoints.xxl.min} {
            padding: ${theme.gap.vlarge} 0;
            max-width: 90%;
            margin: 0 auto;
        }
    `,
);
