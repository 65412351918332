import * as React from 'react';
import { Content, ContentInner, Trigger, Wrapper } from './dropdown.styled';
import { Card } from '../card/card';

interface DropdownPropsInterface {
    content: Array<JSX.Element> | JSX.Element | string;
    children?: JSX.Element | string;
    position?: 'bottomLeft' | 'bottom' | 'bottomRight' | 'topLeft' | 'top' | 'topRight';
    block?: boolean;
}

export const Dropdown: React.FC<DropdownPropsInterface> = ({
    children,
    content,
    position = 'bottomRight',
    block = false,
}) => {
    return (
        <Wrapper block={block}>
            <Trigger block={block}>{children}</Trigger>
            <Content position={position}>
                <Card padding="none" shadow="medium">
                    <ContentInner>{content}</ContentInner>
                </Card>
            </Content>
        </Wrapper>
    );
};
