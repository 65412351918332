import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../form/button/button';

type OffBoardingStep2ActionProps = {
    onContinue: () => void;
    onCancel: () => void;
    isSelectedOption: boolean;
    isFieldValueEmpty: boolean;
    isLoading: boolean;
};

export const OffBoardingStep2Action: React.FC<OffBoardingStep2ActionProps> = observer(
    ({ onContinue, onCancel, isSelectedOption, isFieldValueEmpty, isLoading }: OffBoardingStep2ActionProps) => {
        const { t } = useTranslation();

        return (
            <>
                <Button
                    variant="negative"
                    disabled={!isSelectedOption || isFieldValueEmpty}
                    variation="outlined"
                    onClick={onContinue}
                    loading={isLoading}
                >
                    {t('dialogs.offboarding.step2.button.continue')}
                </Button>
                <Button variant="positive" onClick={onCancel}>
                    {t('dialogs.offboarding.step2.button.cancel')}
                </Button>
            </>
        );
    },
);
