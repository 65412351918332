import styled, { css } from 'styled-components';
import { getOpacity } from '../../../theme/utils/get-rgb';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-right: 7.4rem;

        @media ${theme.breakpoints.vl.min} {
            position: fixed;
            bottom: calc(6.4rem + 4.5rem);
            right: ${theme.gap.medium};
            z-index: 9999;
            flex-direction: column;
            padding-right: unset;
            border: 0.1rem solid ${theme.palette.common.border};
            border-radius: 3.1rem;
            background-color: ${getOpacity(theme.palette.background.body, 0.9)};
            backdrop-filter: blur(0.5rem);
            box-shadow: ${theme.shadow.large};
            transition: border ${theme.base.transition};
        }

        &:hover {
            border-color: ${theme.palette.color.secondary.main};
        }
    `}
`;
