import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../../providers/root-store-provider';
import { DropdownMenu } from '../../dropdown/menu/dropdown-menu';
import { DropdownMenuItem } from '../../dropdown/menu/item/dropdown-menu-item';
import { routes } from '../../../../config/routes';
import { IconSettings } from '../../icons/settings';
import { PortfolioMenuItem } from './item/portfolio-menu-item';
import { IconPortfolio } from '../../icon/portfolio/icon-portfolio';
import { PortfolioTypeEnum } from '../../../../defs/api';

export const PortfolioMenu: React.FC = observer(() => {
    const { t } = useTranslation();
    const { portfolioStore, statisticStore, authStore } = useRootStore();

    const router = useRouter();
    const isIndex = router.pathname === routes.app.index;

    return (
        <DropdownMenu>
            <>
                {portfolioStore.portfolios
                    .filter((p) => {
                        return !(p.type === PortfolioTypeEnum.Summary && portfolioStore.portfolios.length <= 2);
                    })
                    .map((portfolio, index) => (
                        <PortfolioMenuItem
                            key={index}
                            onClick={async () => {
                                await portfolioStore.selectPortfolio(portfolio.id);
                                if (isIndex) {
                                    await statisticStore.profitStore.fetchProfitCharts();
                                }
                            }}
                            name={portfolio.name}
                            selected={portfolio.selected}
                            isSummary={portfolio.type === PortfolioTypeEnum.Summary}
                            color={portfolio.colorName}
                            icon={
                                <IconPortfolio
                                    size={portfolio.type === PortfolioTypeEnum.Summary ? 'large' : 'medium'}
                                    variant={portfolio.colorName}
                                    type={portfolio.iconName}
                                />
                            }
                            value={portfolio.portfolioValue}
                            currencyCode={portfolio.portfolioHistoryCurrencyCode}
                            disabled={authStore.userHasFreeTariff && index !== portfolioStore.portfolios.length - 1}
                        />
                    ))}
                <DropdownMenuItem
                    url={routes.app.portfolios}
                    icon={<IconSettings />}
                    name={t('layout.portfolioMenu.portfolioManagement')}
                />
            </>
        </DropdownMenu>
    );
});
