import React from 'react';
import { IconWrapper, Inner, Label, Value, Wrapper, Content } from './portfolio-menu-item.styled';
import { ValueAmount } from '../../../value/amount/value-amount';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { IconPortfolioVariantType } from '../../../../../theme/type/icon-portfolio-type';

interface PortfolioMenuItemProps {
    name?: string;
    icon?: React.ReactNode;
    value?: number;
    selected?: boolean;
    padding?: boolean;
    onClick?: () => Promise<void> | void;
    currencyCode: string;
    disabled?: boolean;
    isSummary?: boolean;
    color: IconPortfolioVariantType;
}

export const PortfolioMenuItem: React.FC<PortfolioMenuItemProps> = ({
    name,
    onClick,
    selected = false,
    icon,
    value,
    currencyCode,
    disabled = false,
    isSummary = false,
    color,
}: PortfolioMenuItemProps) => {
    const rootStore = useRootStore();
    const { premiumDialogStore } = rootStore;

    const PortfolioMenuItemStyled = () => (
        <Inner>
            <IconWrapper>{icon}</IconWrapper>
            <Content>
                <Label selected={selected}>{name}</Label>
                {value !== undefined && (
                    <Value>
                        <ValueAmount
                            amount={value}
                            currency={currencyCode}
                            mode="pure"
                            nullValue={0}
                            locked={disabled}
                            hidable={true}
                        />
                    </Value>
                )}
            </Content>
        </Inner>
    );

    if (disabled) {
        return (
            <Wrapper
                disabled
                isSummary={isSummary}
                onClick={() => {
                    premiumDialogStore.open();
                }}
                color={color}
                selected={selected}
            >
                <PortfolioMenuItemStyled />
            </Wrapper>
        );
    }

    return (
        <Wrapper isSummary={isSummary} color={color} selected={selected} onClick={onClick}>
            <PortfolioMenuItemStyled />
        </Wrapper>
    );
};
