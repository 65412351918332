import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { DropdownMenu } from '../../../dropdown/menu/dropdown-menu';
import { DropdownMenuItem } from '../../../dropdown/menu/item/dropdown-menu-item';
import { routes } from '../../../../../config/routes';
import { IconSettings } from '../../../icons/settings';
import { Wrapper } from './portfolio-menu-mobile.styled';
import { PortfolioMenuItem } from '../item/portfolio-menu-item';
import { IconPortfolio } from '../../../icon/portfolio/icon-portfolio';

export const PortfolioMenuMobile: React.FC = observer(() => {
    const { t } = useTranslation();
    const { portfolioStore, themeStore, statisticStore } = useRootStore();

    const router = useRouter();
    const isIndex = router.pathname === routes.app.index;

    return (
        <DropdownMenu>
            <Wrapper>
                {portfolioStore.portfolios.map((portfolio, index) => (
                    <PortfolioMenuItem
                        key={index}
                        onClick={async () => {
                            themeStore.hideMobilePortfolioNavigation();
                            await portfolioStore.selectPortfolio(portfolio.id);
                            if (isIndex) {
                                await statisticStore.profitStore.fetchProfitCharts();
                            }
                        }}
                        name={portfolio.name}
                        color={portfolio.colorName}
                        selected={portfolio.selected}
                        icon={<IconPortfolio variant={portfolio.colorName} type={portfolio.iconName} size="vsmall" />}
                        value={portfolio.portfolioValue}
                        currencyCode={portfolio.portfolioHistoryCurrencyCode}
                    />
                ))}
                <DropdownMenuItem
                    onClick={async () => {
                        await router.push(routes.app.portfolios);
                        themeStore.hideMobilePortfolioNavigation();
                    }}
                    icon={<IconSettings />}
                    name={t('layout.portfolioMenu.portfolioManagement')}
                />
            </Wrapper>
        </DropdownMenu>
    );
});
