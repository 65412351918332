import styled, { css } from 'styled-components';
import { IconPortfolioVariantType } from '../../../../../theme/type/icon-portfolio-type';

export const Value = styled.div`
    ${({ theme }) => css`
        color: ${theme.palette.common.textMuted};
    `}
`;

export const Wrapper = styled.li.withConfig({
    shouldForwardProp: (prop) => !['selected', 'disabled', 'isSummary', 'color'].includes(prop),
})<{
    selected: boolean;
    disabled?: boolean;
    isSummary: boolean;
    color: IconPortfolioVariantType;
    onClick?: () => void;
}>`
    ${({ theme, selected, onClick, disabled, isSummary, color }) => css`
        display: flex;
        align-items: center;
        color: ${theme.palette.common.text};
        font-weight: 500;
        padding: ${theme.gap.vsmall} ${theme.gap.large} ${theme.gap.vsmall} ${theme.gap.vsmall};
        border-top: 0.1rem solid ${theme.palette.common.divider};
        transition: color ${theme.base.transition};
        position: relative;

        &:first-child {
            border-top: unset;
        }

        ${onClick &&
        css`
            cursor: pointer;
        `}

        ${disabled &&
        css`
            cursor: not-allowed;
            opacity: 0.4;
            filter: blur(0.2rem);
        `}

        ${isSummary &&
        css`
            font-weight: 700;
            margin: 0 -1.5rem;

            padding: 2rem 3rem;
            border-top: 0.4rem solid ${theme.palette.common.divider};
            border-bottom: 0.1rem solid ${theme.palette.common.divider};

            ${Value} {
                color: ${theme.palette.color[color].dark};
            }

            & + li {
                border-top-color: transparent;
            }

            ${selected &&
            css`
                font-weight: 700;
                border-radius: 10rem;
                margin: 0;
                border-top: 0.1rem solid ${theme.palette.common.divider};
                padding: ${theme.gap.vsmall} ${theme.gap.large} ${theme.gap.vsmall} ${theme.gap.vsmall};

                & + li {
                    border-top-color: transparent;
                }
            `}
        `}
        

        ${selected &&
        css`
            font-weight: 700;
            background-color: ${theme.palette.color.gray.opacity.high};
            border-radius: 10rem;

            & + li {
                border-top-color: transparent;
            }
        `}

        a {
            color: inherit;
            cursor: pointer;
        }
    `}
`;

export const Inner = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.4rem;
`;

export const IconWrapper = styled.div`
    ${({ theme }) => css`
        margin-right: ${theme.gap.vsmall};
        display: inline-flex;
        align-items: center;
        justify-content: center;
    `}
`;

export const Label = styled.div.withConfig({
    shouldForwardProp: (prop) => !['selected'].includes(prop),
})<{
    selected: boolean;
}>`
    ${({ theme, selected }) => css`
        flex: 1;
        transition: color ${theme.base.transition};

        ${Wrapper}:hover & {
            color: ${theme.palette.common.textMuted};
        }

        ${selected &&
        css`
            color: ${theme.palette.common.text};
        `}
    `}
`;
