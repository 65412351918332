import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Button } from '../../form/button/button';
import { BaseDialog } from '../base-dialog';
import { Stack } from '../../stack/stack';
import { InfoBoxPortfolioValue } from '../../info-box/portfolio-value/info-box-portfolio-value';
import { InfoBoxUnrealizedProfit } from '../../info-box/unrealized-profit/info-box-unrealized-profit';
import { InfoBoxRealizedProfit } from '../../info-box/realized-profit/info-box-realized-profit';
import { InfoBoxReportError } from '../../info-box/error-report/info-box-report-error';
import { useRootStore } from '../../../../providers/root-store-provider';
import { routes } from '../../../../config/routes';
import { Title } from './portfolio-value-calculaction-info.styled';

export const PortfolioValueCalculationInfoDialog: React.FC = observer(() => {
    const { t } = useTranslation();
    const { portfolioValueCalculationInfoDialogStore } = useRootStore();
    const router = useRouter();

    const onClose = () => {
        portfolioValueCalculationInfoDialogStore.close();

        if (router.query.portfolioValueCalculationInfo === 'true') {
            router.replace(routes.app.index, undefined, { shallow: true });
        }
    };

    return (
        <>
            <BaseDialog
                opened={portfolioValueCalculationInfoDialogStore.opened}
                close={onClose}
                title={
                    <Title>
                        {t('layout.dialogs.portfolioValueCalculationInfo.title')}
                        <Button variant="primary" size="xsmall" onClick={onClose}>
                            {t('layout.dialogs.portfolioValueCalculationInfo.confirm')}
                        </Button>
                    </Title>
                }
                width={123}
                showCloseButton={false}
                content={
                    <>
                        <InfoBoxPortfolioValue />
                        <InfoBoxUnrealizedProfit />
                        <InfoBoxRealizedProfit />
                        <InfoBoxReportError />
                    </>
                }
                action={
                    <Stack alignItems="center" justifyContent="flex-end" fullWidth>
                        <Button variant="primary" size="medium" onClick={onClose}>
                            {t('layout.dialogs.portfolioValueCalculationInfo.confirm')}
                        </Button>
                    </Stack>
                }
            />
        </>
    );
});
