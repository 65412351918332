import React from 'react';
import { observer } from 'mobx-react-lite';
import { LayoutEnum, ThemeEnum } from '@app/common';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './theme-settings.styled';
import { Switch } from '../form/switch/switch';
import { useRootStore } from '../../../providers/root-store-provider';
import { Tooltip } from '../tooltip/tooltip';
import { IconLightMode } from '../icons/light-mode';
import { IconDarkMode } from '../icons/dark-mode';
import { IconLayoutHorizontal } from '../icons/layout-horizontal';
import { IconLayoutVertical } from '../icons/layout-vertical';

type ThemeSettingsType = {
    theme?: boolean;
    layout?: boolean;
};

export const ThemeSettings: React.FC<ThemeSettingsType> = observer(
    ({ theme = true, layout = true }: ThemeSettingsType) => {
        const { t } = useTranslation();
        const { themeStore, authStore } = useRootStore();
        if (!authStore.userProfile) {
            return null;
        }
        return (
            <Wrapper>
                {theme && (
                    <Tooltip
                        arrowPosition={1}
                        content={
                            themeStore.theme === ThemeEnum.Light
                                ? t('layout.settings.on.theme')
                                : t('layout.settings.off.theme')
                        }
                    >
                        <Switch
                            checked={themeStore.theme === ThemeEnum.Light}
                            name="switchThemeState"
                            iconOn={<IconLightMode />}
                            iconOff={<IconDarkMode />}
                            onChange={async () => {
                                await themeStore.toggleTheme();
                            }}
                        />
                    </Tooltip>
                )}
                {layout && (
                    <Tooltip
                        arrowPosition={1}
                        content={
                            themeStore.layout === LayoutEnum.Horizontal
                                ? t('layout.settings.on.layout')
                                : t('layout.settings.off.layout')
                        }
                    >
                        <Switch
                            checked={themeStore.layout === LayoutEnum.Horizontal}
                            name="switchThemeLayout"
                            iconOn={<IconLayoutHorizontal />}
                            iconOff={<IconLayoutVertical />}
                            onChange={async () => {
                                await themeStore.toggleLayout();
                            }}
                        />
                    </Tooltip>
                )}
            </Wrapper>
        );
    },
);
