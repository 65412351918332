import React from 'react';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '../../../../form/button/button';
import { Stack } from '../../../../stack/stack';
import { Badge } from './offboarding-step4-action.styled';

type OffBoardingStep4ActionProps = {
    onContinue: () => void;
    onCancel: () => void;
    isLoading: boolean;
    isCancelLoading: boolean;
};

export const OffBoardingStep4Action: React.FC<OffBoardingStep4ActionProps> = observer(
    ({ onContinue, onCancel, isLoading, isCancelLoading }: OffBoardingStep4ActionProps) => {
        const { t } = useTranslation();

        return (
            <Stack direction="vertical" alignItems="center" fullWidth>
                <Button variant="positive" block variation="filled" size="large" onClick={onCancel} loading={isLoading}>
                    <Trans
                        i18nKey={'dialogs.offboarding.step4.button.cancel'}
                        components={{ badge: <Badge>0</Badge> }}
                    />
                </Button>
                <Button loading={isCancelLoading} variant={'white'} onClick={onContinue}>
                    {t('dialogs.offboarding.step4.button.continue')}
                </Button>
            </Stack>
        );
    },
);
