import React, { Dispatch, SetStateAction } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@mui/material';
import { Radio } from '../../../form/radio/radio';
import { Wrapper } from './offboarding-step2.styled';
import { TextField } from '../../../form/textfield/textfield';

export type RadioOptionsType =
    | 'highPrice'
    | 'unusedPremiumFeatures'
    | 'competition'
    | 'doneWithInvesting'
    | 'other'
    | null;

type OffBoardingStep2Props = {
    handleChooseOption: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedOption: RadioOptionsType;
    setFieldValue: Dispatch<SetStateAction<string>>;
};

export const OffBoardingStep2: React.FC<OffBoardingStep2Props> = observer(
    ({ handleChooseOption, selectedOption, setFieldValue }: OffBoardingStep2Props) => {
        const { t } = useTranslation();

        const onFieldValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(e.target.value);
        };

        return (
            <Wrapper>
                <RadioGroup name="reason" value={selectedOption} onChange={handleChooseOption}>
                    <Radio label={t('dialogs.offboarding.step2.option.highPrice')} value="highPrice" />
                    <Radio
                        label={t('dialogs.offboarding.step2.option.unusedPremiumFeatures')}
                        value="unusedPremiumFeatures"
                    />
                    <Radio label={t('dialogs.offboarding.step2.option.competition')} value="competition" />
                    {selectedOption === 'competition' && (
                        <TextField
                            label={t('dialogs.offboarding.step2.option.competitionSpecific')}
                            onChange={onFieldValueChange}
                            required
                        />
                    )}
                    <Radio label={t('dialogs.offboarding.step2.option.doneWithInvesting')} value="doneWithInvesting" />
                    <Radio label={t('dialogs.offboarding.step2.option.other')} value="other" />
                    {selectedOption === 'other' && (
                        <TextField
                            label={t('dialogs.offboarding.step2.option.otherSpecific')}
                            onChange={onFieldValueChange}
                            required
                        />
                    )}
                </RadioGroup>
                {/* <More>
                <Button href={'/'} variant="primary" shadow gradient block size={{ xs: 'vsmall', md: 'medium' }}>
                    {t('dialogs.offboarding.step2.fillQuestionnaire')}
                </Button>
            </More> */}
            </Wrapper>
        );
    },
);
